<template>
  <v-app class="page-wrapper service">
    <div class="jumbotron">
      <div class="container">
        <h2 class="mb-4 heading" data-aos="fade-down">Service</h2>
      </div>
    </div>
    <section class="services-area">
      <div class="container">
        <div class="container mt-2">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
              <img
                src="../assets/icons/serviceicon2.gif"
                class="serviceimg"
                alt="Services"
              />

              <p class="card-text">
                Manufacturing and Assemble Products with wide range of materials
                having weights up to 5 tonnes
              </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
              <img
                src="../assets/icons/serviceicon4.gif"
                class="serviceimg"
                alt="Services"
              />
              <p class="card-text">
                Manufacturing Products from Prints (Customer Designs) and with
                our designs.
              </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
              <img
                src="../assets/icons/serviceicon1.gif"
                class="serviceimg"
                alt="Services"
              />
              <p class="card-text">
                Dedicated Team with latest CAD Software (Solidworks 2020
                Professional) for designing Fixtures, Gauges, Tools and SPM’s
              </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
              <img
                src="../assets/icons/serviceicon3.gif"
                class="serviceimg"
                alt="Services"
              />
              <p class="card-text">
                Manufacturing Complex and Precision Machined Components in both
                Metals and Non Metals.
              </p>
            </div>
          </div>
        </div>
        <div class="heading-expertise">
          <h3>We are Expertise in</h3>
        </div>
        <div class="row margin">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div
              class="singleservices manufacture"
              @click="manufacturepop = !manufacturepop"
            >
              <h3>Manufacturing services</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div @click="heavypop = !heavypop" class="singleservices heavyeng">
              <h3>Heavy Engineering</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div
              class="singleservices reverseeng"
              @click="reversepop = !reversepop"
            >
              <h3>Reverse and RE- Engineering</h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Heavy enginneering popup starts -->

    <v-row justify="center">
      <v-dialog v-model="heavypop" scrollable max-width="700px">
        <v-card>
          <div class="title-pop">
            <v-card-title>Heavy Engineering</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                class="mx-2 button"
                fab
                outlined
                small
                @click="heavypop = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-divider></v-divider
          ><v-card-text style="height: 300px">
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Design of large structures as per standards and codes like ASME
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Design of joints/fabrication process and manufacturing engineering
              support
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Preparation of intermediate BOM for procurement
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Design of Gauges & Jigs/Fixture and Special work holding devices
              for large fabrication
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Generation of General Arrangement (GA), fabrication, manufacturing
              drawings and BOM
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Validation of large structures as per standard code calculations
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Heavy enginneering popup Ends -->

    <!-- Reverse enginneering popup starts -->

    <v-row justify="center">
      <v-dialog v-model="reversepop" scrollable max-width="700px">
        <v-card>
          <div class="title-pop">
            <v-card-title>Reverse Engineering</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                class="mx-2 button"
                fab
                outlined
                small
                @click="reversepop = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-divider></v-divider
          ><v-card-text style="height: 300px">
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              The most basic principle of engineering innovation is the
              improvement of what already works.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              We have Ability to make spare parts without removing the original
              part from the existing, Reproducing a part when an original
              drawing does not exist.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Improving productivity, allowing for increased sourcing options
              Re-engineering a specific part associated with down time Deep dive
              into design for manufacturability
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Reverse enginneering popup Ends -->

    <!-- Manufacturing services popup starts -->

    <v-row justify="center">
      <v-dialog v-model="manufacturepop" scrollable max-width="700px">
        <v-card>
          <div class="title-pop">
            <v-card-title>Manufacturing services</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                class="mx-2 button"
                fab
                outlined
                small
                @click="manufacturepop = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-divider></v-divider
          ><v-card-text style="height: 300px">
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Tool and machine design is our main business. The production team
              at our premises consisted of experienced engineers, CAD Engineers
              and Programmers.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Our engineering team has experience in designing and building
              custom machines for a variety of manufacturing operations. Whether
              for assembly, production finishing or material handling.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              We create the most effective solutions for our customers' unique
              machinery needs depending on their specific requirements.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              Our in-house engineering capabilities include mechanical,
              hydraulic, pneumatic, and custom application programming.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              We have built our reputation by providing innovative designs that
              increase efficiency and eliminate manufacturing bottlenecks.
            </p>
            <p class="text-justify">
              <i class="bx bx-right-arrow" style="color: #050674"></i>
              We start by listening to our customers to fully understand their
              expectations and develop a list of process benchmarks.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Manufacturing services popup ends -->

    <!-- Testimonials -->
    <div class="testimonials">
      <div class="heading-expertise" data-aos="fade-down">
        <h3>Testimonials</h3>
      </div>
      <div class="container-fluid">
        <div class=" people">
          <VueSlickCarousel v-bind="settings">
            <div class="px-3 item">
              <div class="box">
                <p class="description">
                  Thanks for providing good services Quality + Cost + Delivery.
                  Keep it up…
                </p>
              </div>
              <div class="author">
                <h5 class="name">Mr.Karthick</h5>
                <p class="title">ZF-Charleston USA</p>
              </div>
            </div>
            <div class=" px-3 item">
              <div class="box">
                <p class="description">
                  Having very good process knowledge and responsive to
                  customers. Delivering Quality products
                </p>
              </div>
              <div class="author">
                <h5 class="name">Mr.Sathish</h5>
                <p class="title">L & T</p>
              </div>
            </div>
            <div class="px-3 item">
              <div class="box">
                <p class="description">
                  You are developing jigs & fixtures in excellent quality with
                  reasonable lead time. Your dimension report for the fixtures &
                  tools supplied is appreciated
                </p>
              </div>
              <div class="author">
                <h5 class="name">Mr.Anand</h5>
                <p class="title">ZF WABCO INDIA</p>
              </div>
            </div>
            <div class="px-3 item">
              <div class="box">
                <p class="description">
                  You’re one of the best suppliers we’ve met
                </p>
              </div>
              <div class="author">
                <h5 class="name">Mr.Praveen</h5>
                <p class="title">PRABHA AUTOS</p>
              </div>
            </div>
            <div class="px-3 item">
              <div class="box">
                <p class="description">
                  You’re developing quality tools in a very short period with
                  reasonable cost
                </p>
              </div>
              <div class="author">
                <h5 class="name">Mr.Balaguru</h5>
                <p class="title">TAFE</p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <!-- end -->
  </v-app>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Services",
  components: { VueSlickCarousel },
  data() {
    return {
      heavypop: false,
      reversepop: false,
      manufacturepop: false,
      settings: {
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: false,
        speed: 5000,
        autoplaySpeed: 3500,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Services",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s"
  }
};
</script>

<style lang="css" scoped>
.serviceimg {
  width: 90px;
  height: 90px;
  margin: auto;
  display: block;
}

.card-text {
  height: 80px;
  text-align: center;
}

span {
  cursor: pointer;
}
.heading-expertise {
  text-align: center;
  font-weight: 700;
  color: 010181;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 70px;
  color: #010181;
}
.title-pop {
  display: flex;
  justify-content: space-evenly;
}
hr {
  margin-top: 0px !important;
}
.button {
  color: #050764 !important;
}
.v-application .primary {
  background-color: 050674 !important;
}
.jumbotron {
  background: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.4),
        rgba(5, 6, 116, 0.6)
      )
      fixed,
    url("../assets/img/servicehead.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 0px !important;
}
/* .section-padding {
  padding: 120px 0;
} */

.heading {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

h1 {
  font-size: 40px;
  line-height: 40px;
  position: relative;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
}
.margin {
  margin-bottom: 20px;
}
.section-title {
  margin-bottom: 50px;
}
.section-title h2 {
  /* margin-top: 20%; */
  font-size: 30px;
  line-height: 45px;
  text-align: left;
  position: relative;
  margin-bottom: 20px;
  color: #333333;
}

.section-title h1 {
  font-size: 40px;
  line-height: 40px;
  position: relative;
  margin-bottom: 20px;
  color: #333333;
  text-transform: capitalize;
}
.singleservices {
  margin: 30px 0;
  cursor: pointer;
}
.heavyeng {
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.3),
      rgba(5, 6, 116, 0.45)
    ),
    url("../assets/img/Heavyengg.jpg") no-repeat center center;
  background-size: 100% 110%;
}
.reverseeng {
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.3),
      rgba(5, 6, 116, 0.85)
    ),
    url("../assets/img/Reverseengg.jpg") no-repeat center center;
  background-size: 100% 110%;
}
.manufacture {
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.3),
      rgba(5, 6, 116, 0.45)
    ),
    url("../assets/img/Manufacturing.jpg") no-repeat center center;
  background-size: 100% 110%;
}
.singleservices {
  padding: 30px 40px;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  color: white;
  position: relative;
  display: block;
  /* transition-delay: 0.1s; */
  transition-duration: 0.5s;
  transition-property: transform;

  height: 320px;
  margin-top: 30px;
}
.singleservices h3 {
  margin-top: 40%;
}

.singleservices:hover {
  color: #fff;
  transform: translateY(-15px);

  cursor: pointer;
}
.testimonials {
  color: #313437;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.testimonials p {
  color: #7d8285;
}

.testimonials h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .testimonials h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.testimonials .people {
  padding: 20px 0 60px;
}

.testimonials .item {
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .testimonials .item {
    height: 220px;
  }
}

.testimonials .item .box {
  padding: 30px;
  background-color: #fff;
  position: relative;
  height: 150px;
}

.testimonials .item .box:after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -24px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-width: 12px 15px;
  border-top-color: #fff;
}

.testimonials .item .author {
  margin-top: 28px;
  padding-left: 25px;
}

.testimonials .item .name {
  font-weight: bold;
  margin-bottom: 2px;
  color: #010181;
}

.testimonials .item .title {
  font-size: 13px;
  color: #9da9ae;
}

.testimonials .item .description {
  font-size: 15px;
  margin-bottom: 0;
}

.testimonials .item img {
  max-width: 40px;
  float: left;
  margin-right: 12px;
  margin-top: -5px;
}
</style>
